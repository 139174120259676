<template>
    <el-drawer
            :visible.sync="isShowHonourRoll.isShow"
            :show-close="false"
            :withHeader="false"
            size="510px"
    >
        <el-row class="topBackground">
            <el-row>
                <el-col :span="8" class="title">光荣榜</el-col>
                <el-col :span="12" style="overflow: hidden">
                    <el-date-picker
                            size="mini"
                            v-model="dateTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </el-col>
                <el-col :span="3">
                    <el-button size="small" type="primary" plain round @click="submit">查询</el-button>
                </el-col>
            </el-row>
            <el-row>

            </el-row>
            <el-row type="flex" justify="end">
                <el-col :span="6">
                    <sup class="fontColor" @click="pipelining" :class="params.pipelining ? 'active' : ''">
                        流水排名 <i class="el-icon-d-caret"></i>
                    </sup>
                </el-col>
                <!--        <el-col :span="6">-->
                <!--          <sup class="fontColor" @click="timeSorting" :class="params.timeSorting ? 'active' : ''">-->
                <!--            时长排名 <i class="el-icon-d-caret"></i>-->
                <!--          </sup>-->
                <!--        </el-col>-->
            </el-row>
        </el-row>
        <el-row type="flex" justify="center" class="main">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="个人榜" name="PERSONAL_LIST">
                    <GroupList :data="data" :flagFlowing="flagFlowing" :pipelining="params.pipelining"
                               :timeSorting="params.timeSorting" :groupType="params.groupType"></GroupList>
                </el-tab-pane>
                <el-tab-pane label="小组榜" name="TEAM_LIST" v-if="rankingTeam">
                    <GroupList :data="data" :flagFlowing="flagFlowing" :pipelining="params.pipelining"
                               :timeSorting="params.timeSorting" :groupType="params.groupType"></GroupList>
                </el-tab-pane>
            </el-tabs>
        </el-row>
    </el-drawer>
</template>

<script>
import GroupList from "@/components/honourRoll/GroupList";
import {rankingList, rankingConfig} from "@/utils/api"

export default {
    name: "HonourRoll",
    props: {
        isShowHonourRoll: {
            type: Object,
            require: false,
            default: () => {
                return {
                    isShow: false
                }
            }
        }
    },
    components: {
        GroupList
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                }
            },
            dateTime: [],
            flagFlowing: true,
            flagDuration: false,
            activeName: 'PERSONAL_LIST',
            params: {//接口参数
                startTime: '',
                endTime: '',
                groupType: 'PERSONAL_LIST',
                type: 'TYPE_FLOWING',
                pipelining: 'FLOWING_DESC',
                timeSorting: '',
            },
            data: [],//数据
            rankingTeam: false
        }
    },
    methods: {
        init() {
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const monthStr = month > 9 ? month : '0' + '' + month;
            const day = now.getDate();
            const dayStr = day > 9 ? day : '0'+ '' + day;
            const today = year + "-" + monthStr + "-" + dayStr;
            this.dateTime = [today, today]
            this.params.startTime = today
            this.params.endTime = today
        },
        config() {
            rankingConfig().then(res => {
                this.rankingTeam = res.data.rankingTeam
            })
        },
        /**
         * 时间范围查询
         * */
        submit() {
            // console.log(this.dateTime,111)
            if (this.dateTime != null || this.dateTime !== undefined || this.dateTime !== '') {
                this.params.startTime = this.dateTime[0];
                this.params.endTime = this.dateTime[1];
                this.rankingList(this.params)
            }
        },
        /**
         * 流水排序
         * */
        pipelining() {
            this.flagFlowing = !this.flagFlowing;
            if (this.flagFlowing === true) {
                this.params.pipelining = 'FLOWING_DESC'
            } else {
                this.params.pipelining = 'FLOWING_ASC'
            }
            this.params.type = 'TYPE_FLOWING';
            this.params.timeSorting = '';
            this.rankingList(this.params)
            this.flagDuration = false;
        },
        /**
         * 时长排序
         * */
        timeSorting() {
            this.flagDuration = !this.flagDuration;
            if (this.flagDuration === true) {
                this.params.timeSorting = 'DURATION_DESC'
            } else {
                this.params.timeSorting = 'DURATION_ASC'
            }
            this.params.type = 'TYPE_DURATION';
            this.params.pipelining = '';
            this.rankingList(this.params)
            this.flagFlowing = false;
        },
        /**
         * 小組/个人切换
         * */
        handleClick(tab, event) {
            // console.log(tab.name)
            this.flagDuration = false;
            this.params.groupType = tab.name;
            this.params.type = 'TYPE_FLOWING';
            this.params.timeSorting = '';
            this.params.pipelining = 'FLOWING_DESC'
            this.rankingList(this.params)
        },
        /**
         * 获取数据
         * */
        rankingList(data) {
            const loading = this.$loading({
                lock: true,//同v-loading的修饰符
                text: '加载中...',//加载文案
                backgroundColor: 'rgba(55,55,55,0.4)',//背景色
                spinner: 'el-icon-loading',//加载图标
                target: document.querySelector(".el-tabs__content")//loading需要覆盖的DOM节点，默认为body
            })
            rankingList(data).then(res => {
                this.data = res.data
                loading.close()
            })
        },
    },
    mounted() {
        this.config();
        this.init();
        this.rankingList(this.params);
    },
}
</script>

<style lang="less" scoped>
.topBackground {
  background: #3D86FF;
  height: auto;
  border-radius: 10px;
  padding-bottom: 100px;

  .title {
    font-weight: bolder;
    font-size: 26px;
    color: white;
    padding-left: 50px;
  }

  .fontColor {
    cursor: pointer;
    color: white;
  }
}

.active {
  color: #F0FF00 !important;
}

.main {
  margin: auto;
  width: 450px;
  background: #FFFFFF;
  box-shadow: 0 4px 16px 0 rgba(0, 136, 255, 0.2);
  border-radius: 7px;
  height: 70vh;
  margin-top: -40px;

  /deep/ .el-tabs__header {
    max-width: 196px;
    margin: -60px auto 15px;

    .el-tabs__item {
      font-size: 26px;
      color: white;
    }

    .el-tabs__item.is-active {
      color: #F0FF00;
    }

    .el-tabs__active-bar {
      background: #F0FF00;
    }
  }

  /deep/ .el-tabs {
    width: 100%;
    padding: 0 40px;
    box-sizing: border-box;
  }

  /deep/ .el-tabs__content .el-tab-pane {
    max-height: 69vh;
    overflow-y: auto;
  }
}

/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 235px;
}
</style>
