<template>
    <div>
        <div v-if="groupType === 'TEAM_LIST'">
            <el-collapse accordion v-if="data.length">
                <el-collapse-item v-for="(item,index) in data" :key="index">
                    <template slot="title">
                        <el-col :span="3" v-if="pipelining === 'FLOWING_DESC' || timeSorting === 'DURATION_DESC'">
                            <img src="../../assets/icon/ranking1.png" alt="" v-if="index===0">
                            <img src="../../assets/icon/ranking2.png" alt="" v-if="index===1">
                            <img src="../../assets/icon/ranking3.png" alt="" v-if="index===2">
                            <span v-if="index>2" style="display:inline-block;text-indent: 8px">{{ index + 1 }}</span>
                        </el-col>
                        <el-col :span="3" v-else>
                            <span style="display:inline-block;text-indent: 8px">{{ index + 1 }}</span>
                        </el-col>
                        <el-col :span="14">
                            {{ item.deptName }}
                        </el-col>
                        <el-col :span="5">
                            <span v-if="timeSorting === ''">{{ item.totalAmount }}元</span>
                            <span v-else>{{ formatSeconds(item.totalDuration) }}</span>
                        </el-col>
                    </template>
                    <template v-if="item.randKingVOList && item.randKingVOList.length">
                        <div v-for="(obj,iindex) in item.randKingVOList" :key="iindex">
                            <el-row>
                                <el-col :span="8" :offset="4">{{ obj.nickName }}</el-col>
                                <el-col :span="5" :offset="5" v-if="timeSorting === ''">{{ obj.totalAmount }}元</el-col>
                                <el-col :span="5" :offset="5" v-else>{{ formatSeconds(obj.totalDuration) }}</el-col>
                            </el-row>
                        </div>
                    </template>
                </el-collapse-item>
            </el-collapse>

            <template v-else>
                <div class="text">暂无数据</div>
            </template>
        </div>
        <div v-else>
            <el-collapse accordion v-if="data.length">
                <el-collapse-item v-for="(item,index) in data" :key="index" class="disable">
                    <template slot="title">
                        <el-col :span="3" v-if="pipelining === 'FLOWING_DESC' || timeSorting === 'DURATION_DESC'">
                            <img src="../../assets/icon/ranking1.png" alt="" v-if="index===0">
                            <img src="../../assets/icon/ranking2.png" alt="" v-if="index===1">
                            <img src="../../assets/icon/ranking3.png" alt="" v-if="index===2">
                            <span v-if="index>2" style="display:inline-block;text-indent: 8px">{{ index + 1 }}</span>
                        </el-col>
                        <el-col :span="3" v-else>
                            <span style="display:inline-block;text-indent: 8px">{{ index + 1 }}</span>
                        </el-col>
                        <el-col :span="13">
                            {{ item.nickName }}
                        </el-col>

                        <el-col :span="8" style="text-align: center">
                            <span v-if="timeSorting === ''">{{ item.totalAmount }}元</span>
                            <span v-else>{{ formatSeconds(item.totalDuration) }}</span>
                        </el-col>
                    </template>
                </el-collapse-item>
            </el-collapse>

            <template v-else>
                <div class="text">暂无数据</div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "GroupList",
    props: {
        data: {
            type: Array,
            require: true,
        },
        pipelining: {
            type: String,
            require: true,
        },
        flagFlowing: {
            type: Boolean,
            require: true,
        },
        timeSorting: {
            type: String,
            require: true
        },
        groupType: {
            type: String,
            require: true
        }
    },
    methods: {
        formatSeconds(value) {
            var theTime = parseInt(value);// 秒
            var theTime1 = 0;// 分
            var theTime2 = 0;// 小时
            if (theTime > 60) {
                theTime1 = parseInt(theTime / 60);
                theTime = parseInt(theTime % 60);
                if (theTime1 > 60) {
                    theTime2 = parseInt(theTime1 / 60);
                    theTime1 = parseInt(theTime1 % 60);
                }
            }

            var result = "" + parseInt(theTime);//秒
            if (10 > theTime > 0) {
                result = "0" + parseInt(theTime);//秒
            } else {
                result = "" + parseInt(theTime);//秒
            }

            if (10 > theTime1 > 0) {
                result = "0" + parseInt(theTime1) + ":" + result;//分，不足两位数，首位补充0，
            } else {
                result = "" + parseInt(theTime1) + ":" + result;//分
            }
            if (theTime2 > 0) {
                result = "" + parseInt(theTime2) + ":" + result;//时
            }
            return result;
        }
    },
    mounted() {
        // console.log(this.groupType)
    },
}
</script>

<style lang="less" scoped>
.disable {
  pointer-events: none;

  /deep/ .el-collapse-item__arrow {
    display: none;
  }
}

.text {
  margin: auto;
  text-align: center;
}

/deep/ .el-tab-pane {
  max-height: 69vh;
  overflow-y: auto;
}
</style>
